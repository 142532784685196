import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';
import Layout from '../components/Layout';
import PortfolioItems from "../components/PortfolioItems";
import FooterDivider from "../components/FooterDivider";
import GenericHeader from "../components/header_section/GenericHeader";

const PortfolioPage = ({data}) => {
  const {markdownRemark: post} = data;
  return (
    <Layout
      meta_image={post.frontmatter.title_image.childImageSharp.fluid.src}
      meta_image_width={post.frontmatter.title_image.childImageSharp.fluid.presentationWidth}
      meta_image_height={post.frontmatter.title_image.childImageSharp.fluid.presentationHeight}
      meta_keyword={`it portfolio, cypress portfolio, app development portfolio, case studies for apps, applications portfolio`}
      meta_description={`Check out our portfolio and learn what spheres we are specialized in. Our mobile applications help businesses in different industries.`}
      local_title={'Portfolio - Cypress'}>
      <GenericHeader title={'Portfolio'}
                     subtitle={`Cypress team develops mobile apps that satisfy the end-users. We are experienced in creating 
                     apps that become successful in various spheres of industry. 
                     Yet, we still focus on new challenges. Check out our previous projects to clearly imagine our consideration.`}
                     title_image={post.frontmatter.title_image}
                     title_image_mobile={post.frontmatter.title_image_mobile}/>
      <PortfolioItems allMarkdownRemark={data.allMarkdownRemark}/>

      <FooterDivider marginTop={2} marginBottom={30}/>
      <br/>
    </Layout>
  )
};

PortfolioPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PortfolioPage

export const portfolioPageQuery = graphql`
  query PortfolioPage($id: String!) {
    markdownRemark(id: {eq: $id}) {
      frontmatter {
        title_image {
          childImageSharp {
            fluid(quality: 100, maxWidth: 916, srcSetBreakpoints: [749, 916], sizes: "(max-width: 1366px) calc(749px), (min-width: 1367px) calc(916px), 100vw") {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        title_image_mobile {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1024, srcSetBreakpoints: [1024]) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    allMarkdownRemark(sort: {order: ASC, fields: [frontmatter___path]}, filter: {frontmatter: {templateKey: {eq: "portfolio-case-page"}}}) {
      edges {
        node {
          frontmatter {
            title
            subtitle
            description
            platforms
            header_color
            platforms_color
            path
            preview_image {
              childImageSharp {
                fluid(quality: 100, maxWidth: 800, srcSetBreakpoints: [663, 800], sizes: "(max-width: 1366px) calc(663px), (min-width: 1367px) calc(800px), 100vw") {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            preview_image_mobile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 800, srcSetBreakpoints: [1023]) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`;
