import React, {memo} from "react";
import {Link} from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import {media} from "./styles/MediaMixins";

const Title = styled.p`
    color: #FFFFFF;
    font-size: ${40 / 16}rem;
    font-weight: bold;
    line-height: ${49 / 16}rem;
`
const SubTitle = styled.p`
    color: #FFFFFF66; 
    font-size: ${18 / 16}rem;
    font-weight: 500;
    line-height: ${32 / 16}rem;
`
const Platforms = styled.p`
    color: ${props => props.platformColor || '#FFFFFF66'};
    font-size: ${14.4 / 16}rem;
    font-weight: 400;
    line-height: ${25.6 / 16}rem;
    margin-top: ${20 / 16}rem;
    margin-bottom: ${15 / 16}rem;
`
const Details = styled.p`
    color: #FFFFFF;
    font-size: ${16 / 16}rem;
    font-weight: 500;
    line-height: ${28 / 16}rem;
    margin-bottom: ${40 / 16}rem;
`

const PortfolioContainer = styled.div`
    width: 100%;
    background-color: ${props => props.color};
    min-height: ${596 / 16}rem;
    display: flex;
    ${media.widescreen`
        max-height: ${596 / 16}rem;
        height: ${596 / 16}rem;
        overflow-y: hidden;
    `};
    ${media.desktop`
        padding-left: 50px;
        padding-right: 50px;
    `};
`

const Row = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    margin: auto;
    ${media.touch`
        flex-direction: column
    `};
    ${media.desktop`
        max-width: 1570px;
    `};
`

const TextItem = styled.div`
    max-width: ${636 / 16}rem;
    ${media.touch`
        margin-top: ${10 / 16}rem;
        margin-bottom: ${50 / 16}rem;
        order: ${props => props.smOrder || 'unset'};
        width: 100%;
        min-width: 100%;
        padding-left: 25px;
        padding-right: 25px;
    `};
    ${media.desktop`
        ${props => props.position === 'left' ?
          `margin-right: 4rem;` :
          `margin-left: 4rem;`
        };
        text-align: ${props => props.position};
        order: ${props => props.lgOrder || 'unset'};
    `};
    ${media.widescreen_only`
        margin-left: 4rem;
        margin-right: 4rem;
        width: 50%;
    `};
    ${media.fullhd`
        width: 100%;
        margin-left: 4rem;
        margin-right: 4rem;
    `};
    ${media.desktop_only`
        margin-left: 4rem;
        margin-right: 4rem;
        width: 35%;
    `};
    text-align: ${props => props.textAlign || 'unset'};
`

const ImageItem = styled.div`
    ${media.touch`
        width: 100%;
        max-width: ${1023 / 16}rem;
        order: ${props => props.smOrder || 'unset'};
        margin-top: ${68 / 16}rem;
        margin-bottom: ${34 / 16}rem;
    `};
    ${media.desktop`
        max-width: ${800 / 16}rem;
        width: 65%;
        order: ${props => props.lgOrder || 'unset'};
    `};
`

const VewCase = styled(Link)`
    border: unset;
    text-align: center;
    font-weight: 700;
    margin-top: ${10 / 16}rem;

    border-radius: ${27.5 / 16}rem;
    ${media.desktop`
        &:hover {
            border: unset;
            background: linear-gradient(315deg, #20BB87 0%, #11998E 100%);
            background-clip: border-box;
            color: #FFFFFF;
        }
    `};
    ${media.touch`
        &:active {
            border: unset;
            background: linear-gradient(315deg, #20BB87 0%, #11998E 100%);
            background-clip: border-box;
            color: #FFFFFF;
        }
        &:hover:not(:active) {
            color: #11998E;
        }
    `};

    background: #FFFFFF;
    background-clip: border-box;
    color: #11998E;
    
    @media (max-width: 768px) {
        font-size: 0.5rem;
        padding-left: ${27 / 16}rem;
        padding-right: ${27 / 16}rem;
        padding-top: ${9 / 16}rem;
        padding-bottom: ${9 / 16}rem;
        line-height: ${10 / 16}rem;
    }
    @media (min-width: 1409px) {
        font-size: 1rem;
        padding-left: ${55 / 16}rem;
        padding-right: ${55 / 16}rem;
        padding-top: ${17 / 16}rem;
        padding-bottom: ${17 / 16}rem;
        line-height: ${19 / 16}rem;
    }
    @media (min-width: 769px) and (max-width: 1408px){
        font-size: calc(0.5rem + 8 * (100vw - 768px) / 640);
        padding-left: calc(${27 / 16}rem + (${55 - 27}) * (100vw - 768px) / 640);
        padding-right: calc(${27 / 16}rem + (${55 - 27}) * (100vw - 768px) / 640);
        padding-top: calc(${9 / 16}rem + (${17 - 9}) * (100vw - 768px) / 640);
        padding-bottom: calc(${9 / 16}rem + (${17 - 9}) * (100vw - 768px) / 640);
        line-height: calc(${10 / 19}rem + (${19 - 10}) * (100vw - 768px) / 640);
    }
    white-space: nowrap;
`

const PortfolioItem = memo(({href, image, image_mobile, title, subtitle, platforms, platforms_color, details, position, color}) => {
    const image_element = <Img imgStyle={{height: 'auto'}} alt={`Portfolio ${title}`}
                               fluid={[
                                   image_mobile.childImageSharp.fluid,
                                   {
                                       ...image.childImageSharp.fluid,
                                       media: `(min-width: 1024px)`,
                                   }
                               ]
                               }/>;
    const content = <>
        <Title>{title}</Title>
        <SubTitle>{subtitle}</SubTitle>
        <Platforms platformColor={platforms_color}>{platforms}</Platforms>
        <Details>{details}</Details>
        <VewCase to={href}>
            VIEW CASE
        </VewCase>
    </>;
    return (
        <PortfolioContainer color={color}>
            <Row>
                <ImageItem smOrder={1} lgOrder={position === 'left' ? 2 : 1}>
                    {image_element}
                </ImageItem>
                <TextItem position={position} smOrder={2} lgOrder={position === 'left' ? 1 : 2}
                          breakpoint={'desktop'} maxWidth={636}>
                    {content}
                </TextItem>
            </Row>
        </PortfolioContainer>
    )
});

export default ({allMarkdownRemark}) => {
    const {edges: posts} = allMarkdownRemark;
    return (
        <>
            {
                posts.map(({node: post}, index) => (
                    <PortfolioItem
                        key={index}
                        href={`/portfolio/${post.frontmatter.path}/`}
                        image={post.frontmatter.preview_image}
                        image_mobile={post.frontmatter.preview_image_mobile}
                        title={post.frontmatter.title}
                        subtitle={post.frontmatter.subtitle}
                        platforms={post.frontmatter.platforms.join('\u00A0\u00A0\u00A0\u00A0')}
                        platforms_color={post.frontmatter.platforms_color}
                        details={post.frontmatter.description}
                        position={index % 2 === 0 ? 'right' : 'left'}
                        color={post.frontmatter.header_color}/>
                ))
            }
        </>
    )
};